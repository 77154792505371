import GlobalLayout from "components/page/GlobalLayout";
import SensorOverviewPage from "components/sensors/SensorOverviewPage";
import React from "react";
import Helmet from "react-helmet";

export default function Senors() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Environmental and Security IoT Sensors</title>
        <meta
          name="description"
          content="Complete visibility in any environment. Identify smoke, vape, unauthroized access, motion, glassbreak, and more."
        />
      </Helmet>
      <SensorOverviewPage />
    </GlobalLayout>
  );
}
